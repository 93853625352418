.app {
  background-size: cover;
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-position:center;
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.9) 100%, rgba(0, 0, 0, 1) 100%), url('../../public/images/SDS.png');
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.9) 100%, rgba(0, 0, 0, 1) 100%), url('../../public/images/SDS.png');
  background-image: -o-linear-gradient(rgba(0, 0, 0, 0.9) 100%, rgba(0, 0, 0, 1) 100%), url('../../public/images/SDS.png');
  background-image: -ms-linear-gradient(rgba(0, 0, 0, 0.9) 100%, rgba(0, 0, 0, 1) 100%), url('../../public/images/SDS.png');
  background-image: linear-gradient(rgba(0, 0, 0, 0.9) 100%, rgba(0, 0, 0, 1) 100%), url('../../public/images/SDS.png');
}